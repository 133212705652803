<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
          <div>
            <h1 class="intro-section-title">7. Zorggebruik door de tijd</h1>
          </div>
        </div>
      </div>


      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="'Percentage met identificatie in jaren voor overlijden'" :hovertext="'Populatie: Iedereen die is overleden tussen 2017-2020 en op het moment van overlijden is geïdentificeerd als persoon met dementie. Voor de periode 2017-2020, maximaal 3 jaar vóór overlijden, wordt gekeken of de persoon al geïdentificeerd is als persoon met dementie.'" 
            :x="zorgkantoorregionaam_table" :y="zorggebruikddt_t_table" 
            :z="dementie_landelijk_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Jaar'"  :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 3000">
            <template slot="card-header">Percentage met identificatie in jaren voor overlijden</template>
            <div slot="card-body">
              Het percentage van mensen dat is geïdentificeerd als persoon met dementie van drie jaar vóór overlijden tot het overlijdensjaar. In het jaar van overlijden heeft iedereen de diagnose dementie.
              <line-chart-for v-if="load.graphOne"
              :tickvals="[-4,-3,-2,-1,0]"
              :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
              :xtitle="''"
              :ytitle="'% met diagnose dementie'" :xdata="t_dementie_landelijk" :ydata="dementie_landelijk" :zorgkantoor="zorgkantoor"/>
            </div>
          </card>
          <card-small :cardId="'Percentage met identificatie in jaren voor overlijden'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Percentage met identificatie in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card-small :cardId="'Eerste identificatie is op basis van medicatie'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. De variabelen geven weer op basis van welke type zorggebruik voor het eerst dementie werd geïdentificeerd. Dit is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is geïdentificeerd op basis van Wlz-zorg/indicatie, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hierarchie: medicatie>ziekenhuis>wlz> overig.'">
            <template slot="card-header">Eerste identificatie is op basis van medicatie</template>
            <div slot="card-body">
              De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd
            </div>
          </card-small>


          <card-small :cardId="'Eerste identificatie is op basis van medicatie'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Eerste identificatie is op basis van medicatie - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
         <card-small :cardId="'Eerste identificatie is op basis van ziekenhuis diagnose'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. De variabelen geven weer op basis van welke type zorggebruik voor het eerst dementie werd geïdentificeerd. Dit is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is geïdentificeerd op basis van Wlz-zorg/indicatie, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hierarchie: medicatie>ziekenhuis>wlz> overig.'">
          <template slot="card-header">Eerste identificatie is op basis van ziekenhuis diagnose</template>
          <div slot="card-body">
            De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd
          </div>
        </card-small>


          <card-small :cardId="'Eerste identificatie is op basis van ziekenhuis diagnose'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Eerste identificatie is op basis van ziekenhuis diagnose - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card-small :cardId="'Eerste identificatie is op basis van wlz indicatie'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. De variabelen geven weer op basis van welke type zorggebruik voor het eerst dementie werd geïdentificeerd. Dit is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is geïdentificeerd op basis van Wlz-zorg/indicatie, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hierarchie: medicatie>ziekenhuis>wlz> overig.'">
            <template slot="card-header">Eerste identificatie is op basis van wlz indicatie</template>
            <div slot="card-body">
              De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd
            </div>
          </card-small>

          <card-small :cardId="'Eerste identificatie is op basis van wlz indicatie'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Eerste identificatie is op basis van wlz indicatie - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card-small :cardId="'Eerste identificatie is op basis van overige diagnose'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. De variabelen geven weer op basis van welke type zorggebruik voor het eerst dementie werd geïdentificeerd. Dit is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is geïdentificeerd op basis van Wlz-zorg/indicatie, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hierarchie: medicatie>ziekenhuis>wlz> overig.'">
            <template slot="card-header">Eerste identificatie is op basis van overige diagnose</template>
            <div slot="card-body">
              De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd
            </div>
          </card-small>

          <card-small :cardId="'Eerste identificatie is op basis van Overig'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Eerste identificatie is op basis van Overig - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="'Percentage in verpleeghuis in jaren voor overlijden'" :hovertext="'Populatie: Iedereen die is overleden tussen 2017-2020 en op het moment van overlijden is geïdentificeerd als persoon met dementie. Van al deze personen is gekeken of zij op in het jaar van overlijden en in de drie jaar vóór overlijden in een verpleeghuis woonden. Let op: mensen kunnen ook in een verpleeghuis wonen o.b.v. een andere indicatie dan dementie.'" 
            :x="zorgkantoorregionaam_table" :y="zorggebruikddt_t_table" 
            :z="verpleeghuis_landelijk_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Jaar'"  :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 3000">
            <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden</template>
            <div slot="card-body">
              Het percentage mensen dat in een verpleeghuis woont, van drie jaar vóór overlijden tot het overlijdensjaar.
              <line-chart-for v-if="load.graphOne"
              :tickvals="[-4,-3,-2,-1,0]"
              :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
              :xtitle="''"
              :ytitle="'% in verpleeghuis'"
              :xdata="t_dementie_landelijk"
              :ydata="verpleeghuis_landelijk"
              :zorgkantoor="zorgkantoor"/>
            </div>
          </card>
          <card-small :cardId="'Percentage in verpleeghuis in jaren voor overlijden'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="'Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden'" :hovertext="'Populatie: Iedereen die is overleden tussen 2017-2020 en op het moment van overlijden de diagnose dementie heeft. Van al deze personen is gekeken of zij in het jaar van overlijden en in de 3 jaar vóór overlijden hulpmiddelen of diensten vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook hulpmiddelen of diensten ontvangen o.b.v. een andere indicatie dan dementie.'" 
            :x="zorgkantoorregionaam_table" :y="zorggebruikddt_t_table" 
            :z="hulpmiddelendiensten_landelijk_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Jaar'"  :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 3000">
            <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden</template>
            <div slot="card-body">
              Het percentage mensen dat hulpmiddelen of diensten ontvangen vanuit Wmo, van drie jaar vóór overlijden tot het overlijdensjaar.
              <line-chart-for v-if="load.graphOne"
              :tickvals="[-4,-3,-2,-1,0]"
              :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
              :xtitle="''"
              :ytitle="'% met hulpmiddelen of diensten'"
              :xdata="t_dementie_landelijk"
              :ydata="hulpmiddelendiensten_landelijk"
              :zorgkantoor="zorgkantoor"/>
            </div>
          </card>
          <card-small :cardId="'Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden dementienetwerk'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
          <card :cardId="'Hoeveel mensen met dementie bezoeken het ziekenhuis'" :hovertext="'Populatie: Iedereen die is overleden tussen 2017-2020 en op het moment van overlijden de diagnose dementie heeft. Van al deze personen is gekeken of zij in het jaar van overlijden en in de 3 jaar vóór overlijden zijn opgenomen in een ziekenhuis. Let op: mensen kunnen ook hulpmiddelen of diensten ontvangen o.b.v. een andere indicatie dan dementie.'" 
            :x="t_table" :y="percentage_opname_ziekenhuis_table.map(value => `${value}%`)"
            :xtitle="'Jaar'"  :ytitle="'Percentage'" 
            :tableHeight="300">
            <template slot="card-header">Hoeveel mensen met dementie bezoeken het ziekenhuis?</template>
            <div slot="card-body">
              Het percentage van mensen met dementie dat is opgenomen in het ziekenhuis, van drie jaar vóór overlijden tot het overlijdensjaar. Deze informatie is alleen op landelijk niveau beschikbaar.
              <line-chart-t v-if="load.graphTwo"
              :tickvals="[-4,-3,-2,-1,0]"
              :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
              :xtitle="''"
              :ytitle="'% opgenomen in ziekenhuis'"
              :bottom="40"
              :data1="[{ mode: 'line', name:'Landelijk percentage',
              x: t , y: percentage_opname_ziekenhuis, marker: {
              color: 'rgba(180,180,180,1.00)'}}]"/>
            </div>
          </card>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="'Gebruik van Wmo op enig moment in de laatste drie levensjaren'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment ondersteuning vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook ondersteuning hebben ontvangen o.b.v. een andere indicatie dan dementie.'" 
            :x="zorgkantoorregionaam_wmo" :y="percentage_enigGebruikWmo_table.map(value => `${value}%`)"
            :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
            :tableHeight="1000">
            <template slot="card-header">Gebruik van Wmo op enig moment in de laatste drie levensjaren</template>
            <div slot="card-body">
              Het percentage van mensen met dementie dat in het jaar van overlijden of de drie jaar daarvoor op enig moment Wmo-ondersteuning heeft gekregen.
              <stacked-bar-chart-colors v-if="load.graphThree" 
              :ytitle="'% gebruik Wmo'"
              :names="['Wmo']" 
              :x="[zorgkantoorregionaam_wmo]"
              :y="[percentage_enigGebruikWmo]"
              :showlegend="false"
              :ticksuffix="'%'"/>
            </div>
          </card>
          <card-small :cardId="'Gebruik van Wmo in de laatste levensjaren'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Gebruik van Wmo in de laatste levensjaren - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel==='zorgkantoor'">
        <div class="col-sm-12 down">
          <card :cardId="'Ooit opgenomen in verpleeghuis in laatste levensjaren'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment zijn opgenomen in een verpleeghuis. Let op: mensen kunnen ook zijn opgenomen o.b.v. een andere indicatie dan dementie.'" 
            :x="zorgkantoorregionaam_vh" :y="percentage_enigGebruikVerpleeghuis_table.map(value => `${value}%`)"
            :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
            :tableHeight="1000">
            <template slot="card-header">Ooit opgenomen in verpleeghuis in laatste levensjaren</template>
            <div slot="card-body">
              Het percentage van mensen met dementie dat in jaar van overlijden of de drie jaar daarvoor op enig moment zijn opgenomen in een verpleeghuis.
              <stacked-bar-chart-colors v-if="load.graphThree" 
              :ytitle="'% opgenomen in verpleeghuis'"
              :names="['verpleeghuis']"
              :x="[zorgkantoorregionaam_vh]"
              :y="[percentage_enigGebruikVerpleeghuis]"
              :showlegend="false"
              :ticksuffix="'%'"/>
            </div>
          </card>
          <card-small :cardId="'fOoit opgenomen in verpleeghuis in laatste levensjaren'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Ooit opgenomen in verpleeghuis in laatste levensjaren - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Percentage met identificatie in jaren voor overlijden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="zorggebruikddt_t" :y="dementie_landelijk_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Percentage met identificatie in jaren voor overlijden</template>
                <div slot="card-body">
                  Deze grafiek toont het nationale gemiddelde van het percentage mensen bij wie dementie is vastgesteld, van drie jaar vóór het overlijden tot het jaar van overlijden. Bij iedereen wordt dementie vastgesteld in het jaar van overlijden.
                  <stacked-bar-chart-colors v-if="load.graphFour"
                    :ytitle="'% met diagnose dementie'"
                    :x="[zorggebruikddt_t]"
                    :y="[dementie_landelijk]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Percentage in verpleeghuis in jaren voor overlijden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="zorggebruikddt_t" :y="verpleeghuis_landelijk_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden</template>
                <div slot="card-body">
                  Deze grafiek toont het landelijk gemiddelde van het percentage mensen dat in een verpleeghuis woont, van drie jaar vóór het overlijden tot het jaar van overlijden.
                  <stacked-bar-chart-colors v-if="load.graphFour"
                    :ytitle="'% in verpleeghuis'"
                    :x="[zorggebruikddt_t]"
                    :y="[verpleeghuis_landelijk]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="zorggebruikddt_t" :y="hulpmiddelendiensten_landelijk_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
                :tableHeight="600">
                <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden</template>
                <div slot="card-body">
                  Deze grafiek toont het landelijk gemiddelde van het percentage mensen dat hulpmiddelen of diensten uit de Wmo ontvangt, van drie jaar vóór het overlijden tot het jaar van overlijden.
                  <stacked-bar-chart-colors v-if="load.graphFour"
                    :ytitle="'% met hulpmiddelen of diensten'"
                    :x="[zorggebruikddt_t]"
                    :y="[hulpmiddelendiensten_landelijk]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Hoeveel mensen met dementie bezoeken het ziekenhuis'" :hovertext="'Populatie: Iedereen die is overleden tussen 2017-2020 en op het moment van overlijden de diagnose dementie heeft. Van al deze personen is gekeken of zij in het jaar van overlijden en in de 3 jaar vóór overlijden zijn opgenomen in een ziekenhuis. Let op: mensen kunnen ook hulpmiddelen of diensten ontvangen o.b.v. een andere indicatie dan dementie.'" 
                :x="zorggebruik_t" :y="percentage_opname_ziekenhuis_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Hoeveel mensen met dementie bezoeken het ziekenhuis?</template>
                <div slot="card-body">
                  Deze grafiek toont het landelijk gemiddelde van het percentage mensen met dementie dat in het ziekenhuis is opgenomen, van drie jaar vóór het overlijden tot het jaar van overlijden. Deze informatie is alleen op nationaal niveau beschikbaar.
                  <stacked-bar-chart-colors v-if="load.graphFour"
                    :ytitle="'% met hulpmiddelen of diensten'"
                    :x="[zorggebruik_t]"
                    :y="[percentage_opname_ziekenhuis]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="row" v-if="selectedLevel === 'landelijk'">
                <div class="col-sm-6 down">
                  <card :cardId="'Gebruik van Wmo op enig moment in de laatste drie levensjaren'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment ondersteuning vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook ondersteuning hebben ontvangen o.b.v. een andere indicatie dan dementie.'" 
                    :x="zorgkantoorregionaam_wmo" :y="percentage_enigGebruikWmo_table.map(value => `${value}%`)"
                    :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
                    :tableHeight="300">
                    <template slot="card-header">Gebruik van Wmo op enig moment in de laatste drie levensjaren</template>
                    <div slot="card-body">
                      Deze grafiek toont het landelijk gemiddelde van het percentage mensen met dementie dat op enig moment in het overlijdensjaar of de drie jaar daarvoor Wmo-ondersteuning heeft ontvangen.
                      <stacked-bar-chart-colors v-if="load.graphFour"
                        :isLandelijk='false'
                        :ytitle="'% gebruik Wmo'"
                        :x="[zorgkantoorregionaam_wmo]"
                        :y="[percentage_enigGebruikWmo]"
                        :showlegend="false"
                        :ticksuffix="'%'"/>
                    </div>
                  </card>
                </div>

                <div class="col-sm-6 down">
                  <card :cardId="'Ooit opgenomen in verpleeghuis in laatste levensjaren'" :hovertext="'Populatie: iedereen die in het geselecteerde jaar is overleden en geïdentificeerd als persoon met dementie. Van al deze personen is gekeken of zij in het jaar van overlijden of de drie jaar daarvoor op enig moment zijn opgenomen in een verpleeghuis. Let op: mensen kunnen ook zijn opgenomen o.b.v. een andere indicatie dan dementie.'" 
                    :x="zorgkantoorregionaam_vh" :y="percentage_enigGebruikVerpleeghuis_table.map(value => `${value}%`)"
                    :xtitle="'Regio naam'"  :ytitle="'Percentage'" 
                    :tableHeight="300">
                    <template slot="card-header">Ooit opgenomen in verpleeghuis in laatste levensjaren</template>
                    <div slot="card-body">
                      Deze grafiek toont het landelijk gemiddelde van het percentage mensen met dementie dat op enig moment in het jaar van overlijden of in de drie jaar daarvoor in een verpleeghuis is opgenomen.
                      <stacked-bar-chart-colors v-if="load.graphFour"
                        :isLandelijk='false'
                        :ytitle="'% opgenomen in verpleeghuis'"
                        :x="[zorgkantoorregionaam_vh]"
                        :y="[percentage_enigGebruikVerpleeghuis]"
                        :showlegend="false"
                        :ticksuffix="'%'"/>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>

        <!-- <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'factsheet7-landelijk-6'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'">
                <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden</template>
                <div slot="card-body">
                  Deze grafiek toont het landelijk gemiddelde van het percentage mensen dat in een verpleeghuis woont, van drie jaar vóór het overlijden tot het jaar van overlijden.
                  <stacked-bar-chart-colors v-if="load.graphFour"
                    :ytitle="'% opgenomen in verpleeghuis'"
                    :x="[zorggebruikddt_t]"
                    :y="[hulpmiddelendiensten_landelijk]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div> -->

      <!-- <div class="row">
        <div class="col-sm-12 down">
          <card v-if="selectedLevel==='zorgkantoor'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS microdata'">
            <template slot="card-header">Dagen tot eerste opname</template>
            <div slot="card-body">
              Dagen tot eerste opname van overleden mensen met dementie
              <stacked-bar-chart-colors-numbers v-if="load.graphThree" :showlegend="false" :name1="'Eerste opname'"
              :ytitle="'Dagen tot eerste opname'"
              :x1="zorgkantoorregionaam_eo"
              :y1="gemDagenEersteOpname"/>
            </div>
          </card>
          <card-small v-if="selectedLevel==='dementienetwerk'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
            <template slot="card-header">Dagen tot eerste opname - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div> -->

      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>

</div>
</template>

<script>

// import SideBarRight from '../components/SideBarRight'
import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import LineChartFor from '../components/LineChartFor'
import LineChartT from '../components/LineChartT-4'
import StackedBarChartColors from '../components/StackedBarChartColors'
// import StackedBarChartColorsNumbers from '../components/StackedBarChartColorsNumbers'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Zorg door de tijd',
  },
  components: { Card, CardSmall, LineChartT, LineChartFor, StackedBarChartColors, Footer},
  data () {
    return {
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false
      },
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      percentage_opname_ziekenhuis_table:[],
      t_table:[],
      t_dementie_landelijk: [],
      dementie_landelijk: [],
      verpleeghuis_landelijk: [],
      hulpmiddelendiensten_landelijk: [],
      zorgkantoorregiocodes: [],
      zorgkantoor: [],
      zorggebruikddt_t: [],
      t: [],
      zorggebruik_t: [],
      percentage_opname_ziekenhuis: [],
      zorgkantoorregionaam_med: [],
      zorgkantoorregionaam_wmo: [],
      zorgkantoorregionaam_vh: [],
      zorgkantoorregionaam_zh: [],
      zorgkantoorregionaam_wlz: [],
      zorgkantoorregionaam_eo: [],
      gemDagenEersteOpname: [],
      percentage_enigGebruikVerpleeghuis: [],
      percentage_enigGebruikVerpleeghuis_table: [],
      percentage_enigGebruikWmo: [],
      percentage_enigGebruikWmo_table: [],
      eerste_diagnose_overig: [],
      eerste_diagnose_ziekenhuis: [],
      eerste_diagnose_wlz: [],
      dementie_landelijk_table :[],
      verpleeghuis_landelijk_table : [],
      hulpmiddelendiensten_landelijk_table : [],
      zorgkantoorregionaam_table: [],
      zorggebruikddt_t_table: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_us_zorggebruikddt/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.zorgkantoorregiocode-b.zorgkantoorregiocode)
          this.zorgkantoorregiocodes = [...new Set(response.data.map(d => d.zorgkantoorregiocode))]
          for (var i = 0; i<this.zorgkantoorregiocodes.length; i++) {
            this.dementie_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.percentage_dementie))
            this.verpleeghuis_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.percentage_verpleeghuiszorg))
            this.hulpmiddelendiensten_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.percentage_hulpmiddelenDiensten))
            this.t_dementie_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.t))
            this.zorgkantoor[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.zorgkantoorregionaam))[0]
            }


            // manually mapping the t codes
            const tMapping = {
            '-4': '4 jaar voor overlijden',
            '-3': '3 jaar voor overlijden',
            '-2': '2 jaar voor overlijden',
            '-1': '1 jaar voor overlijden',
            '0': 'jaar van overlijden'
          };

            this.zorggebruikddt_t_table =  response.data.map(d => tMapping[d.t]);
            this.zorgkantoorregionaam_table = response.data.map(d => d.zorgkantoorregionaam)
            this.dementie_landelijk_table = response.data.map(d => (d.percentage_dementie).toFixed(1))
            this.verpleeghuis_landelijk_table = response.data.map(d => (d.percentage_verpleeghuiszorg).toFixed(1))
            this.hulpmiddelendiensten_landelijk_table = response.data.map(d => (d.percentage_hulpmiddelenDiensten).toFixed(1))

          this.load.graphOne=true
        }
      ),
        this.$http.get(`/api/zorgkantoor_us_zorggebruik/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage_enigGebruikWmo-a.percentage_enigGebruikWmo)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.percentage_enigGebruikWmo = response.data.map(d => (d.percentage_enigGebruikWmo))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_eersteDiagnDementieOverig-a.percentage_eersteDiagnDementieOverig)
            this.eerste_diagnose_overig = response.data.map(d => (d.percentage_eersteDiagnDementieOverig))
            this.zorgkantoorregionaam_overig = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_eersteDiagnDementieZiekenhuis-a.percentage_eersteDiagnDementieZiekenhuis)
            this.eerste_diagnose_ziekenhuis = response.data.map(d => (d.percentage_eersteDiagnDementieZiekenhuis))
            this.zorgkantoorregionaam_zh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_eersteDiagnDementieWlz-a.percentage_eersteDiagnDementieWlz)
            this.eerste_diagnose_wlz = response.data.map(d => (d.percentage_eersteDiagnDementieWlz))
            this.zorgkantoorregionaam_wlz = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_enigGebruikVerpleeghuis-a.percentage_enigGebruikVerpleeghuis)
            this.percentage_enigGebruikVerpleeghuis = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis))
            this.zorgkantoorregionaam_vh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.gemDagenEersteOpname-a.gemDagenEersteOpname)
            // this.gemDagenEersteOpname = response.data.map(d => (d.gemDagenEersteOpname).toFixed(0))
            this.gemDagenEersteOpname = response.data.map(d => (isNaN(d.gemDagenEersteOpname) ? 0 : Number(d.gemDagenEersteOpname).toFixed(0)))

            this.zorgkantoorregionaam_eo = response.data.map(d => d.zorgkantoorregionaam)

            this.percentage_enigGebruikVerpleeghuis_table = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis).toFixed(1))
            this.percentage_enigGebruikWmo_table = response.data.map(d => (d.percentage_enigGebruikWmo).toFixed(1))
            this.load.graphThree=true
          }
        ),
        this.$http.get(`/api/zorggebruik_nationaal/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.zorgkantoorregiocode-b.zorgkantoorregiocode)

          // manually mapping the t codes
            const tMapping = {
            '-4': '4 jaar voor overlijden',
            '-3': '3 jaar voor overlijden',
            '-2': '2 jaar voor overlijden',
            '-1': '1 jaar voor overlijden',
            '0': 'jaar van overlijden'
          };

          this.t_table =  response.data.map(d => tMapping[d.t]);
          this.t = response.data.map(d => (d.t))
          this.percentage_opname_ziekenhuis = response.data.map(d => (d.percentage_opname_ziekenhuis))
          this.percentage_opname_ziekenhuis_table = response.data.map(d => (d.percentage_opname_ziekenhuis).toFixed(1))
          this.load.graphTwo=true
        }
      )
    }    
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_us_zorggebruikddt/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.t - b.t)
            this.zorggebruikddt_t = response.data.map(d => d.t)
            this.dementie_landelijk = response.data.map(d => (d.percentage_dementie))
            this.verpleeghuis_landelijk = response.data.map(d => (d.percentage_verpleeghuiszorg))
            this.hulpmiddelendiensten_landelijk = response.data.map(d => (d.percentage_hulpmiddelenDiensten))

            this.dementie_landelijk_table = response.data.map(d => (d.percentage_dementie).toFixed(1))
            this.verpleeghuis_landelijk_table = response.data.map(d => (d.percentage_verpleeghuiszorg).toFixed(1))
            this.hulpmiddelendiensten_landelijk_table = response.data.map(d => (d.percentage_hulpmiddelenDiensten).toFixed(1))
            this.load.graphFour=true
          }
        ),
        this.$http.get(`/api/zorggebruik_nationaal/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
        (response) => {
          response.data.sort((a,b) => a.t - b.t)
          this.zorggebruik_t = response.data.map(d => (d.t))
          this.percentage_opname_ziekenhuis = response.data.map(d => (d.percentage_opname_ziekenhuis))
          this.percentage_opname_ziekenhuis_table = response.data.map(d => (d.percentage_opname_ziekenhuis).toFixed(1))
          this.load.graphTwo=true
        }
      ),
        this.$http.get(`/api/zorgkantoor_us_zorggebruik/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage_enigGebruikWmo-a.percentage_enigGebruikWmo)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.percentage_enigGebruikWmo = response.data.map(d => (d.percentage_enigGebruikWmo))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_eersteDiagnDementieOverig-a.percentage_eersteDiagnDementieOverig)
            this.eerste_diagnose_overig = response.data.map(d => (d.percentage_eersteDiagnDementieOverig))
            this.zorgkantoorregionaam_overig = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_eersteDiagnDementieZiekenhuis-a.percentage_eersteDiagnDementieZiekenhuis)
            this.eerste_diagnose_ziekenhuis = response.data.map(d => (d.percentage_eersteDiagnDementieZiekenhuis))
            this.zorgkantoorregionaam_zh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_eersteDiagnDementieWlz-a.percentage_eersteDiagnDementieWlz)
            this.eerste_diagnose_wlz = response.data.map(d => (d.percentage_eersteDiagnDementieWlz))
            this.zorgkantoorregionaam_wlz = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_enigGebruikVerpleeghuis-a.percentage_enigGebruikVerpleeghuis)
            this.percentage_enigGebruikVerpleeghuis = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis))
            this.zorgkantoorregionaam_vh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.gemDagenEersteOpname-a.gemDagenEersteOpname)
            // this.gemDagenEersteOpname = response.data.map(d => (d.gemDagenEersteOpname).toFixed(0))
            this.gemDagenEersteOpname = response.data.map(d => (isNaN(d.gemDagenEersteOpname) ? 0 : Number(d.gemDagenEersteOpname).toFixed(0)))

            this.zorgkantoorregionaam_eo = response.data.map(d => d.zorgkantoorregionaam)

            this.percentage_enigGebruikVerpleeghuis_table = response.data.map(d => (d.percentage_enigGebruikVerpleeghuis).toFixed(1))
            this.percentage_enigGebruikWmo_table = response.data.map(d => (d.percentage_enigGebruikWmo).toFixed(1))
            this.load.graphThree=true
          }
        )
      }
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)
  }
}

</script>

<style scoped>

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}


</style>